import { Context } from '@nuxt/types'

// Navis /sso endpoint
// this feature should meet the requirements from Navis: https://moneyforward.kibe.la/notes/55782
//
// <詳細フロー>
// 1. /sso?login_hint=...&tenant_uid=...&redirect_path=... にアクセスされる
// 2. middleware/sso-redirect.ts が /mfid_signin?login_hint=...&tenant_uid=...&redirect_path=... にリダイレクトする
// 3. pages/mfid_signin.vue が一番最初に middleware/authenticated-signin.ts を実行する
// 4. 次に pages/mfid_signin.vue が実行される
export default function (context: Context): void {
  const navisSupport = context.store.state.navisSupport as boolean
  if (!navisSupport) {
    return
  }

  if (context.route.path === '/sso') {
    const query = context.route.fullPath.includes('?')
      ? context.route.fullPath.slice(context.route.fullPath.indexOf('?'))
      : ''

    context.redirect('/mfid_signin' + query)
  }
}
