import { Context } from '@nuxt/types'
import { GraphQLError } from 'graphql'
import { isApolloError } from 'apollo-client'
import authQuery from '~/queries/auth.gql'
import { AuthQuery } from '~/types/eldamar'
import { emitError, isGraphQLForbiddenError } from '~/utils/nuxt-helper'

const UNAUTHORIZED = 'unauthorized'

const isUnauthorized = (errs: ReadonlyArray<GraphQLError>): boolean => {
  const s = errs.some((err) => {
    if (err.extensions) {
      return err.extensions.code === UNAUTHORIZED
    }
  })
  return s
}

const getRedirectPath = (context: Context): string | undefined => {
  const path = context.route.query.redirect_path
  return typeof path === 'string' ? path : undefined
}

const getLoginHint = (context: Context): string | undefined => {
  const hint = context.route.query.login_hint
  return typeof hint === 'string' ? hint : undefined
}

const getTenantUid = (context: Context): string | undefined => {
  const uid = context.route.query.tenant_uid
  return typeof uid === 'string' ? uid : undefined
}

// See: middleware/sso-redirect.ts
const handleNavisSsoRedirectPath = async (context: Context, email: string | null, mfidLinked: boolean | null): Promise<boolean> => {
  const redirectPath = getRedirectPath(context) // optional
  const loginHint = getLoginHint(context) // required
  const tenantUid = getTenantUid(context) // required

  // 3つ全部未指定の場合はredirectPathの処理を行わない
  if (redirectPath === undefined && loginHint === undefined && tenantUid === undefined) {
    return false
  }

  const navisSupport = context.store.state.navisSupport as boolean
  if (!navisSupport) {
    context.error({ statusCode: 403 })
    return true
  }

  // TODO: いずれはtenantUserLinkedみたいなのを作ってそれをチェックするようにする
  if (!mfidLinked) {
    context.error({ statusCode: 403 })
    return true
  }

  // 3つのうちいずれかが指定されている場合は、loginHintとtenantUidが必須
  if (loginHint === undefined || tenantUid === undefined) {
    context.error({ statusCode: 400 })
    return true
  }

  if (redirectPath !== undefined && redirectPath !== '' && !redirectPath.startsWith('/')) {
    context.error({ statusCode: 400 })
    return true
  }

  if (loginHint !== email) {
    // 要求されたアカウントでログイン済みではないということなのでmiddleware側では何もせずに pages/mfid_signin.vue 側でMFIDログインが発火するべき
    // See: middleware/sso-redirect.ts
    return true
  }

  // TODO: switchSeller if tenantUid is not same as current seller

  const defaultPage = await context.store.dispatch('seller/getDefaultPage', null, { root: true })
  context.redirect(redirectPath || defaultPage)

  return true
}

export default async function (context: Context): Promise<void> {
  if (process.client) {
    return
  }
  const c = context.app.apolloProvider?.defaultClient
  if (c) {
    let email: string | null = null
    let mfidLinked: boolean | null = null
    try {
      const res = await c.query<AuthQuery>({
        query: authQuery,
      })
      email = res.data?.user?.email ?? null
      mfidLinked = res.data?.user?.mfidLinked ?? null
    } catch (e) {
      if (!isApolloError(e)) {
        emitError(context, e)
        return
      }
      if (isUnauthorized(e.graphQLErrors) || isGraphQLForbiddenError(e)) {
        return
      }
      emitError(context, e)
      return
    }
    try {
      // See: middleware/sso-redirect.ts
      const pathName = context.req.url ? new URL(context.req.url, 'http://dummy').pathname : ''
      if (pathName === '/mfid_signin' && await handleNavisSsoRedirectPath(context, email, mfidLinked)) {
        return
      }

      const defaultPage = await context.store.dispatch('seller/getDefaultPage', null, { root: true })
      context.redirect(defaultPage)
    } catch (e) {
      emitError(context, e)
    }
  }
}
