import { format } from 'date-fns-tz'
import jaJP from 'date-fns/locale/ja'
import Vue from 'vue'
import { PaymentOption, TaxRateType } from '~/types/eldamar'

Vue.filter('comma', (num?: number | string): string => {
  if (num !== 0 && !num) {
    return ''
  }
  const target: number = typeof num === 'string' ? parseFloat(num) : num
  return target.toLocaleString('ja-JP', { maximumFractionDigits: 4 })
})

Vue.filter('formatDate', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
})

Vue.filter('formatDateJPWithWeek', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'M月d日(E)', { timeZone: 'Asia/Tokyo', locale: jaJP })
})

Vue.filter('formatDateJPWithYear', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'yyyy年M月d日', { timeZone: 'Asia/Tokyo', locale: jaJP })
})

Vue.filter('formatDateJP', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'M月d日', { timeZone: 'Asia/Tokyo', locale: jaJP })
})

Vue.filter('formatShortDate', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'M/dd', { timeZone: 'Asia/Tokyo' })
})

Vue.filter('formatDateTime', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'yyyy/MM/dd HH:mm:ss', { timeZone: 'Asia/Tokyo' })
})

Vue.filter('readablePaymentOptions', (ps: PaymentOption[]): string => {
  return ps.map((p) => {
    switch (p) {
      case 'BankTransfer':
        return '銀行振込'
      case 'AccountTransfer':
        return '口座振替'
      case 'ConvenienceBilling':
        return 'コンビニ払い'
      case 'CardBilling':
        return 'カード払い'
    }
  }).join('・')
})

Vue.filter('taxRateText', (tr: TaxRateType): string => {
  switch (tr) {
    case 'TaxRateType10':
      return '10%'
    case 'TaxRateType8':
      return '8%'
    case 'TaxRateTypeReduced8':
      return '8%(軽減税率)'
    case 'TaxRateTypeTransitionalMeasures8':
      return '8%(経過措置)'
    case 'TaxRateTypeFree':
      return '非課税'
    case 'TaxRateTypeNotApplicable':
      return '対象外'
    case 'TaxRateTypeUnknown':
    default:
      return ''
  }
})
